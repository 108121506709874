import * as React from 'react';
import Typography from '@mui/material/Typography';
import ProductHeroLayout from './heroLayout';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundPosition: 'center',
      }}
    >
      <Typography color="inherit" align="center" variant="h2" marked="center" style={{ zIndex: 1 }}>
        Welcome to my blog!
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h3" style={{ zIndex: 1, fontSize: "1.4rem" }}
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
        I write about software development, cloud engineering, &amp; automation
      </Typography>
    </ProductHeroLayout>
  );
}
