import React, { useState, useEffect } from "react"

import { newSubscriber, removeSubscriber, sendNotification } from "../utils/customApiCall"
import { getUserEmail } from "../utils/profileService"

const arrayBufferToBase64 = (buffer) => {
    // https://stackoverflow.com/a/9458996
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

const saveSubscriptionToServer = async (subscription) => {
    const accessToken = localStorage?.getItem("accessToken").replaceAll('"', "")
    var deviceType = "";
    if (navigator.userAgent.match(/Tablet|iPad/i)) {
        deviceType = "tablet";
    } else if (navigator.userAgent.match(/Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i)) {
        deviceType = "mobile";
    } else {
        deviceType = "desktop";
    }
    const email = `${getUserEmail()}-${deviceType}-${Date.now()}`
    const subscriber = {
        email: email,
        url: subscription.endpoint,
        auth: arrayBufferToBase64(subscription.getKey('auth')),
        p256dh: arrayBufferToBase64(subscription.getKey('p256dh'))
    }
  try {
    const saveResponse = await newSubscriber(accessToken, JSON.stringify(subscriber))
    if (saveResponse.status === 200) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

const removeSubscriptionFromServer = async (subscriptionEndpoint) => {
    const accessToken = localStorage?.getItem("accessToken").replaceAll('"', "")
  try {
    const delResponse = await removeSubscriber(accessToken, JSON.stringify({ endpoint: subscriptionEndpoint }))
    if (delResponse.status === 204) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

const hasNotificationPermission = async () => {
  if (Notification.permission === "granted") {
    return true
  } else if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      return true
    }
  }
  return false
}

const pushSupported = () => {
  if (typeof window !== `undefined`) {
    if ("PushManager" in window) {
      return true
    }
  }
  return false
}

const PushNotification = () => {
  const [subscribed, setSubscribed] = useState(false)
  const [working, setWorking] = useState(false)

  const createSubscription = async () => {
    const hasPermission = await hasNotificationPermission()
    if (pushSupported() && "serviceWorker" in navigator && hasPermission) {
      setWorking(true)
      navigator.serviceWorker.ready
        .then(async (swRegistration) => {
          const pushSubscription = await swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: "BGS5cOBQJywHq8oFZKYRsAd8KYMUPdljLnM-hj9no1cbc6YOsr6B_u9LeLv-jbz9frZiP9fgf-AU2RveAloTNNQ",
          })
          const subSaved = await saveSubscriptionToServer(pushSubscription)
          if (subSaved) {
            setSubscribed(true)
            localStorage.setItem("PUSH_NOTIFICATION_SUBSCRIBED", "1")
            console.log("Push subscription confirmed", "success")
            setWorking(false)

            showLocalNotification('This is title', 'this is the message', swRegistration);

            navigator.setAppBadge(1)

            swRegistration.onmessage = (event) => {
              console.log(`Client Side Push Caught!! - ${JSON.stringify(event)}`)
            }
          } else {
            console.log("An eror occured", "error")
            setWorking(false)
          }
        })
        .catch((error) => {
          console.log(error)
          console.log(
            "An error occured setting up push notification",
            "warning"
          )
          setWorking(false)
        })
    } else {
      console.log("Notifications not allowed.", "error")
    }
  }

  const unsubscribe = async () => {
    if (pushSupported() && subscribed) {
      setWorking(true)
      navigator.serviceWorker.ready
        .then(async (swRegistration) => {
          const subscription = await swRegistration.pushManager.getSubscription()
          await subscription.unsubscribe()
          removeSubscriptionFromServer(subscription.endpoint).then(() => {
            localStorage.setItem("PUSH_NOTIFICATION_SUBSCRIBED", "0")
            console.log("Push notifications disabled", "success")
            setWorking(false)
          })
        })
        .catch((e) => {
          setWorking(false)
        })
      setSubscribed(false)
    }
  }

  const showLocalNotification = (title, body, swRegistration) => {
    const options = {
        body,
        // here you can add more properties like icon, image, vibrate, etc.
    };
    swRegistration.showNotification(title, options);
  }

  const sendPushNotification = async (message) => {
      const accessToken = localStorage.getItem("accessToken").replaceAll('"', "")
      const email = JSON.parse(localStorage?.getItem("authClaims")).preferred_username
      const notification = {
        email: email,
        message: message,
        url: "https://google.com"
      }
      navigator.setAppBadge(1)
      return await sendNotification(accessToken, JSON.stringify(notification))
  }

  useEffect(() => {
    const push_sub = localStorage.getItem("PUSH_NOTIFICATION_SUBSCRIBED")
    if (push_sub === "1") {
      setSubscribed(true)
    }
  }, [])

  useEffect(() => {
    //always update sw
    if (pushSupported() && "serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((swRegistration) =>
        swRegistration.update()
      )
    }
  }, [])

  if (!pushSupported()) {
    return null
  }
  let btnText = subscribed
    ? "Unsubscribe from push notifications"
    : "Enable push notifications"
  btnText = working ? "busy..." : btnText
  const callback = subscribed ? unsubscribe : createSubscription

  return (
    <div>
        <button
            className="notification-btn"
            onClick={callback}
            disabled={working}
            tabIndex={0}
        >
            {btnText}
        </button>

        { subscribed ? <div>
            <input id="push" type="text" size="10" onKeyDown={(e) => {
                if (e.code !== "Enter") return;
                sendPushNotification(e.currentTarget.value);
                e.currentTarget.value = '';
            }} />
            <button onClick={(e) => {
                let pushInput = global.window.document.querySelector("#push")
                sendPushNotification(pushInput.value);
                pushInput.value = '';
            }} type="button">Send</button>
        </div> : null}
    </div>
  )
}

export default PushNotification

