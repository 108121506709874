import React from "react";
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import "./index.css"

import Layout from "../components/layout"
import AboutTheBlog from "../components/aboutTheBlog"
import Image from "../components/image"
import PushNotification from "../components/pushNotifications"
import ProductHero from "../components/productHero"
import Seo from "../components/seo"
import BlogPreview from "../components/blogPreview"

import { isiOS, isPWA } from '../utils/deviceService'

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout>
        <Seo title="Home" description="Here is a decently long website description! Here is a decently long website description! Here is a decently long website description!" />
        <Container maxWidth="xl">
          <Box sx={{ my: 4 }}>
            <ProductHero />
            <Typography variant="h4" component="h1" gutterBottom>
              N8 Duff Blog
            </Typography>
            <p>Welcome to your new Gatsby site.</p>
            <p>Now go build something great.</p>
            <div
              data-testid="gatsby-logo"
              style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}
            >
              <Image />
            </div>
            <p>
              No blog posts found. Add markdown posts to "content/blog" (or the
              directory you specified for the "gatsby-source-filesystem" plugin in
              gatsby-config.js).
            </p>
            <Link to="/page-2/" color="secondary">Go to page 2</Link>
          </Box>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo title="Home" description="Here is a decently long website description! Here is a decently long website description! Here is a decently long website description!" />
        <Container maxWidth="xl">
        <Box sx={{ my: 4 }}>
          <ProductHero />
          <Box className={isiOS() ? isPWA() ? "ios pwa" : "ios" : isPWA() ? "web pwa" : "web"} style={{ color: "inherit" }}>
            <div className="anchor" id="bio"></div>
            <Typography variant="h4" component="h2" gutterBottom>
              About Me
            </Typography>
            <Grid container spacing={3} columns={16}>
              <Grid item xs={16} sm={6} md={4}>
                <StaticImage
                  src="../images/nate.jpg"
                  alt="Nate Duff"
                  layout="fixed"
                  className="bioPic"
                  style={{ height: "150px", width: "150px", borderRadius: "250px" }}
                  // style={{ float: "right" }}
                  // width={250}
                  //className="certificationBadge"
                />
              </Grid>
              <Grid item xs={16} sm={10} md={8} className="bioBody">
                <p>
                  I have spent the past 7 years learning to develop, test, &amp; automate solutions in Microsoft 365, the Azure Cloud, &amp; Azure DevOps and at this point I think I have a knack for it but I can for sure tell you that I absolutely enjoy it. So I figured why not put all that passion and experience to good use and write a blog!
                </p>
                <p>
                  These days I might be creating a public facing application written in ReactJS (like this one), public or internal applications written in Blazor, automating regular disaster recovery validation using Azure Pipeines &amp; PowerShell scripts, or even creating infrastructure-as-code using Terraform and Bicep.
                </p>
                <p>
                  I have a unique and diligent approach when it comes to application lifecycle management, I speak better PowerShell than I ever did Spanish, but most importenly I love saving money &amp; solving problems so if you are interested stick around!
                </p>
              </Grid>
              <Grid item xs={0} sm={0} md={4} sx={{ display: { xs: "none", md: "initial" }}}>
                <StaticImage
                  src="../images/certs/CERT-Expert-DevOps-Engineer-600x600.png"
                  alt="Azure Admin Expert"
                  layout="constrained"
                  width={250}
                  className="certificationBadge"
                  style={{ display: { xs: "none", md: "initial" } }}
                  sx={{ display: { xs: "none", md: "initial" } }}
                />
              </Grid>
            </Grid>
            <Link to="/resume">
              Go to Resume page
            </Link>
            <Typography variant="h4" component="h2" gutterBottom style={{ marginTop: "25px" }}>
              About the Blog
            </Typography>
            <AboutTheBlog />
            <Link to="/how-its-made">
              Learn How It's Made
            </Link>
            <Typography variant="h4" component="h2" gutterBottom style={{ marginTop: "30px" }}>
              Latest Posts
            </Typography>
            <AuthenticatedTemplate>
              <p>Hello {global.window ? JSON.parse(global.window.localStorage.getItem("authClaims"))?.name : null}</p>
              <PushNotification />
            </AuthenticatedTemplate>
            <Box sx={{ display: 'grid', gap: 4, gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'} }}>
              {posts.map(post => {
                  return (
                    <BlogPreview key={post.fields.slug} post={post} />
                  )
              })}
            </Box>
            <Link to="/blogs">
              All Blogs
            </Link>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
      filter: {frontmatter: {draft: {ne: true}}}) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          previewImage
        }
      }
    }
  }
`
