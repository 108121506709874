import * as React from "react"
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import "./aboutTheBlog.css"

const steps = [
    {
      label: 'Overview',
      description: `This site serves as my blog, portfolio site, and as an example of the type of solution that I enjoy building and managing in Azure. I wrote the YAML pipeline & infrastructure-as-code definitions, back-end Azure Function API, and the front-end ReactJS site myself. The following sections describe these areas in a bit more detail but full blog posts will also be available soon for an even deeper dive.`,
    },
    {
      label: 'Infrastructure',
      description:
        'Like almost of my solutions, this one is stored in Azure Repos and has a build pipeline written in YAML. The YAML pipeline is pretty extensive and dynamic to enable building & pubilshing only the web or api project, running or skipping unit tests, publishing unit test & code coverage results, and even includes an option to run performance tests using the new Azure Load Test service. The Azure infrastrcture for the solution is defined in Bicep, and is both lint tested & validated via the pipeline during the application build, and deployed at the start of the release stage for certain branches.',
    },
    {
      label: 'Back-End',
      description: `By design, the typical blog user will not need to log-in or make requests to an API. The Azure Function back-end API can be used by users who wish to provide feedback on the Coming Soon page, but doing so requires an authorized account. By utilizing AzureAD B2C for external user identity management, anyone can sign up for an account either by registering with a valid email or by using a Facebook, Google, or Twitter account. Once you are logged-in you should be authorized to make requests to the back-end API!`,
    },
    {
      label: 'Front-End',
      description: `My preference for front-end applications is Blazor and I will admit I initially wrote this blog in Blazor Web Assembly, but when it comes to public facing sites and sites that need SEO (Search Engine Optomization) I find that JavaScript - more specifically GatsbyJS is the better choice. Blog content is stored in a blob and blog details are stored in a table in an Azure Storage Account, and during application build time the blogs are downloaded and formatted into static blog posts by GatsbyJS & GraphQL requests. Once you combine static content & a PWA (Progressive Web Application) you get a fast, cheap, and reliable website that can easily be hosted in Azure Static WebApps!`,
    },
  ];

export default function AboutTheBlog() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

  return (
     <Box sx={{ maxWidth: { xs: "100%", md: "60%" }, marginBottom: "25px", marginLeft: { xs: "inherit", md: "20%" } }}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepButton onClick={handleStep(index)}>
              {step.label}
            </StepButton>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  {index === steps.length - 1 ? null : <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Continue
                  </Button>}
                  {index === 0 ? null : <Button
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
