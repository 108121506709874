export function getUserEmail() {
    if (typeof global?.window?.localStorage !== "undefined") {
        let claims = JSON.parse(global.window.localStorage.getItem("authClaims"))
        let email = claims?.preferred_username

        if (typeof email === "undefined") {
            return claims?.emails[0]
        }
        else {
            return email;
        }
    }
}
